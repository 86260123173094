@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap";
.wallet-adapter-button {
  color: #fff;
  cursor: pointer;
  height: 48px;
  background-color: #0000;
  border: none;
  border-radius: 4px;
  align-items: center;
  padding: 0 24px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
  display: flex;
}

.wallet-adapter-button-trigger {
  background-color: #512da8;
}

.wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: #fff;
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-button[disabled] {
  color: #999;
  cursor: not-allowed;
  background: #404144;
}

.wallet-adapter-button-end-icon, .wallet-adapter-button-start-icon, .wallet-adapter-button-end-icon img, .wallet-adapter-button-start-icon img {
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wallet-adapter-button-end-icon {
  margin-left: 12px;
}

.wallet-adapter-button-start-icon {
  margin-right: 12px;
}

.wallet-adapter-collapse {
  width: 100%;
}

.wallet-adapter-dropdown {
  display: inline-block;
  position: relative;
}

.wallet-adapter-dropdown-list {
  z-index: 99;
  grid-row-gap: 10px;
  opacity: 0;
  visibility: hidden;
  background: #2c2d30;
  border-radius: 10px;
  grid-template-rows: 1fr;
  margin: 0;
  padding: 10px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  list-style: none;
  transition: opacity .2s, transform .2s, visibility .2s;
  display: grid;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  height: 37px;
  color: #fff;
  border: none;
  border-radius: 6px;
  outline: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-modal-collapse-button svg {
  fill: #999;
  align-self: center;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
  transition: transform .15s ease-in;
  transform: rotate(180deg);
}

.wallet-adapter-modal {
  opacity: 0;
  z-index: 1040;
  background: #00000080;
  transition: opacity .15s linear;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1;
}

.wallet-adapter-modal-button-close {
  cursor: pointer;
  background: #1a1f2e;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
  position: absolute;
  top: 18px;
  right: 18px;
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: #fff;
}

.wallet-adapter-modal-button-close svg {
  fill: #777;
  transition: fill .2s;
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
}

.wallet-adapter-modal-overlay {
  background: #00000080;
  position: fixed;
  inset: 0;
}

.wallet-adapter-modal-container {
  min-height: calc(100vh - 6rem);
  justify-content: center;
  align-items: center;
  margin: 3rem;
  display: flex;
}

@media (max-width: 480px) {
  .wallet-adapter-modal-container {
    min-height: calc(100vh - 2rem);
    margin: 1rem;
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  z-index: 1050;
  max-width: 400px;
  background: #10141f;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: flex;
  position: relative;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%;
}

.wallet-adapter-modal-title {
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 64px 48px 48px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

@media (max-width: 374px) {
  .wallet-adapter-modal-title {
    font-size: 18px;
  }
}

.wallet-adapter-modal-list {
  width: 100%;
  margin: 0 0 12px;
  padding: 0;
  list-style: none;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  border-radius: 0;
  font-size: 18px;
  font-weight: 400;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon, .wallet-adapter-modal-list .wallet-adapter-button-start-icon, .wallet-adapter-modal-list .wallet-adapter-button-end-icon img, .wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
  width: 28px;
  height: 28px;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
  opacity: .6;
  margin-left: auto;
  font-size: 14px;
}

.wallet-adapter-modal-list-more {
  cursor: pointer;
  color: #fff;
  background-color: #0000;
  border: none;
  align-self: flex-end;
  align-items: center;
  padding: 12px 24px 24px 12px;
  display: flex;
}

.wallet-adapter-modal-list-more svg {
  fill: #fff;
  margin-left: .5rem;
  transition: all .1s;
}

.wallet-adapter-modal-list-more-icon-rotate {
  transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 24px;
  display: flex;
}

.wallet-adapter-modal-middle-button {
  cursor: pointer;
  width: 100%;
  color: #fff;
  background-color: #512da8;
  border: none;
  border-radius: 8px;
  margin-top: 48px;
  padding: 12px;
  font-size: 18px;
  display: block;
}

/*# sourceMappingURL=index.4577c3ff.css.map */
